import { defineNuxtRouteMiddleware } from '#app';
import useProductStore from '~/stores/products';
import { useNuxtApp } from '#imports';
import type { TypedRoute } from '@typed-router';

export default defineNuxtRouteMiddleware(async (to) => {
    // skip middleware on the initial client load
    const nuxtApp = useNuxtApp();
    if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return;

    const route = to as TypedRoute;

    const routesNeedingProductLoaded: TypedRoute['name'][] = [
        'admin-products-id',
        'admin-products-id-edit',
        'admin-products-id-edit-variations',
        'products-slug'
    ];

    if (!routesNeedingProductLoaded.includes(route.name)) {
        return;
    }

    const productStore = useProductStore(useNuxtApp().$pinia);

    await productStore.loadProducts();

    // @ts-expect-error - this does exist given the above route check
    const idIsSlug = isNaN(Number(route.params.id));
    const product = productStore.products.find(
        // @ts-expect-error - this does exist given the above route check
        p => (idIsSlug ? p.slug : p.id) === (idIsSlug ? route.params.slug : Number(route.params.id))
    );

    if (product) {
        productStore.productId = product.id;
        return;
    }

    // todo - this creates a loop
    // const isAdmin = to.name.includes('admin');
    // const pathSegments = to.path.split('/').filter(Boolean);
    // const route: Partial<TypedRoute> = isAdmin
    //     ? { name: 'admin-all', params: { all: pathSegments } }
    //     : { name: 'all', params: { all: pathSegments } };
    //
    // return navigateTo(route, { redirectCode: 404 });

    return abortNavigation(createError({
        statusCode: 404,
        message: 'Product not found',
        statusMessage: 'Not Found'
    }));
});
